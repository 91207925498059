//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: { type: Object, default: () => {} },
    to: { type: String, required: true }
  }
};
